import React from 'react'
import { Anchor, Divider } from 'antd'
import Container from '../../components/Landing/Container'
import { isMobile } from 'react-device-detect'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../../components/seo'

const { Link } = Anchor

export default function TaxYearGuidePage({ setCookieValue, data }) {
    const affixContainerStyle: React.CSSProperties = isMobile
        ? {}
        : { display: 'grid', gridTemplateColumns: '1fr 6fr 1fr', marginLeft: 10, marginRight: 10 }

    return (
        <Container setCookieValue={setCookieValue} pathName="tax-year">
            <SEO title="Skatterapport Guide" />
            <span style={affixContainerStyle}>
                {!isMobile && (
                    <Anchor offsetTop={100} targetOffset={156}>
                        <Link href="#guide-title" title="Skatteår">
                            <Link href="#step-1" title="1. Rapport-siden" />
                            <Link href="#step-2" title="2. Opret skatteår" />
                            <Link href="#step-3" title="3. Oversigt" />
                        </Link>
                        <Link href="#rapport-section" title="Skatterapporter">
                            <Link href="#step-4" title="4. Interaktivt regneark" />
                        </Link>
                        <Link href="#finish-section" title="Færdiggør">
                            <Link href="#step-5" title="5. Lås skatteåret" />
                            <Link href="#step-6" title="6. Lås skatteåret" />
                            <Link href="#step-7" title="7. Aflever til SKAT" />
                        </Link>
                    </Anchor>
                )}
                <div>
                    <div
                        style={{
                            margin: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            // flexWrap: 'wrap',
                            marginBottom: 100,
                        }}
                        id="guide-title"
                    >
                        <h1 style={{ fontSize: 40 }} className="landing-guide-title-h">
                            Opret Skatterapporter
                        </h1>
                        <Img
                            title="Guide Image"
                            alt="step 2"
                            style={{ marginBottom: 100 }}
                            className="landing-guide-illustration-big"
                            fluid={data.s2.childImageSharp.fluid}
                        />
                        <p className="landing-guide-header-p">
                            Med skatterapporter binder du al din data sammen. Det er dem der i
                            sidste ende udgør din dokumentation til Skattestyrelsen.
                            Skatterapporterne på ll33a.dk er tilpasset skatteåret. Dvs. når du
                            opretter et skatteår, "Tax Year", vil der automatisk blive genereret
                            rapporter som indeholder al relevant data for det pågældende skatteår.
                        </p>
                    </div>
                    <Divider />
                </div>
            </span>
        </Container>
    )
}

export const query = graphql`
    query {
        s1: file(relativePath: { eq: "guide-assets/skatterapporter-step-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s2: file(relativePath: { eq: "guide-assets/skatterapporter-step-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s3: file(relativePath: { eq: "guide-assets/skatterapporter-step-3.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s4: file(relativePath: { eq: "guide-assets/skatterapporter-step-4.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s5: file(relativePath: { eq: "guide-assets/skatterapporter-step-5.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s6: file(relativePath: { eq: "guide-assets/skatterapporter-step-6.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        s7: file(relativePath: { eq: "guide-assets/skatterapporter-step-7.png" }) {
            childImageSharp {
                fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`
